import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'games',
    redirectTo: 'games/donut-cop',
    pathMatch: 'full',
  },
  {
    path: 'games',
    data: { state: 'games' },
    children: [
      {
        path: 'donut-cop',
        loadChildren: () =>
          import('./games/games.module').then((m) => m.GamesModule),
        data: { state: 'donut-cop' },
      },
      {
        path: 'naviy',
        loadChildren: () =>
          import('./games/games.module').then((m) => m.GamesModule),
        data: { state: 'naviy' },
      },
    ],
  },
  {
    path: 'about',
    redirectTo: 'about/team-shane',
    pathMatch: 'full',
  },
  {
    path: 'about',
    data: { state: 'about' },
    children: [
      {
        path: 'team-shane',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
        data: { state: 'team-shane' },
      },
      {
        path: 'team-john',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
        data: { state: 'team-john' },
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
        data: { state: 'news' },
      },
    ],
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
    data: { state: 'contact' },
  },
  {
    // create route guards for loading the stuff
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
    data: { state: 'blog' },
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: { state: 'home' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
