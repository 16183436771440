<ng-container>
  <div class="loading" [style.display]="loading ? 'block' : 'none'"></div>
  <div
    class="bg-wrapper"
    [ngClass]="getState(o)"
    [class.ui-disabled]="!(isInteractive$ | async)"
    (click)="closeMenu($event)"
  >
    <app-menu class="d-block" [ngClass]="getMenuClass(o)"></app-menu>
    <div id="main-content">
      <div class="router-wrapper" [@moveFromBottom]="getState(o)">
        <router-outlet #o="outlet"> </router-outlet>
      </div>
    </div>

    <div class="container">
      <div class="row overflow-hidden">
        <div
          class="col-12 position-fixed scroll-down-section p-0"
          (click)="navigateBottom()"
        >
          <p class="h6 font-weight-bold text-uppercase text-center pb-1">
            Scroll Down
          </p>
          <div id="scroll-down"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
